body {
  margin: 0;
  background-color:  #ecf1f4;
}

@font-face {
  font-family: 'FuturaBookC';
  src: local('FuturaBookC'),
    url(./assets/fonts/FuturaBookC.ttf) format('truetype');
}
@font-face {
  font-family: 'FuturaMediumC';
  src: local('FuturaMediumC'),
    url(./assets/fonts/FuturaMediumC.ttf) format('truetype');
}
@font-face {
  font-family: 'FuturaDemiC';
  src: local('FuturaDemiC'),
    url(./assets/fonts/FuturaDemiC.ttf) format('truetype');
}
