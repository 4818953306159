.react-calendar {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 940px;
  max-width: 100%;
  height: 562px;
  background: white;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  box-shadow: 0px 6px 14px rgba(2, 9, 75, 0.06);
  border-radius: 8px;
}
.react-calendar__viewContainer {
  width: 700px;
}

.react-calendar-month
  .react-calendar--doubleView
  .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
  text-decoration: none;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  color: #757575;
  text-decoration: none;
}
.react-calendar__month-view__weekdays__weekday:nth-last-child(-n + 2) {
  color: #f0640c;
}

.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  background: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #35414c;
  position: relative;
}

.react-calendar__tile abbr {
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  color: #35414c;
}

.react-calendar__tile:disabled {
  color: #35414c;
  opacity: 0.4;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background: #ffffff;
  box-shadow: 0px 2px 5px rgba(10, 21, 128, 0.15);
  border-radius: 8px;
}
.react-calendar__tile--now {
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffff;
  box-shadow: 0px 2px 5px rgba(10, 21, 128, 0.15);
  border-radius: 8px;
}
.react-calendar__tile--hasActive {
  background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}
.react-calendar__tile--active > * {
  /* overflow: visible !important;
  box-sizing: content-box; */
}

.react-calendar__tile--active abbr {
  color: #f0640c;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active,
.react-calendar__tile--active:enabled:focus {
  overflow: visible !important;
  z-index: 10;
}
.react-calendar--selectRange .react-calendar__tile--hover {
}

.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__navigation__prev2-button {
  display: none;
}

.react-calendar__navigation__prev-button {
  background: #ffffff;
  box-shadow: 0px 2px 5px rgba(10, 21, 128, 0.1);
  border-radius: 8px;
  color: #f0640c;
  margin-right: 45px;
}

.react-calendar__navigation__next-button {
  background: #ffffff;
  box-shadow: 0px 2px 5px rgba(10, 21, 128, 0.1);
  border-radius: 8px;
  color: #f0640c;
}

.react-calendar__navigation__label {
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 22px;
  color: #35414c;
}

.react-calendar__navigation button {
  margin-left: 45px;
}

.react-calendar__month-view__days__day--weekend {
}

/* Tile dropdown styles */

.tile__dropdown {
  display: none;
  border: 0px;
}

/* .react-calendar__tile--active * {
  visibility: visible;
} */

.react-calendar__tile--active:focus .tile__dropdown,
.react-calendar__tile--active:hover .tile__dropdown {
  border-top: 1px solid rgba(0, 0, 0, 0.144);
  margin-top: 5px;
  height: auto;
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0px 2px 5px rgba(10, 21, 128, 0.15);
  border-radius: 0px 0px 8px 8px;
  padding: 0.75em 0.5em;

  position: absolute;
  top: 45px;
  width: 100%;
}

.tile__dropdown .tile__line {
  height: 20px;
}
.tile__dropdown .tile__line:hover {
  color: #f0640c;
}
.react-calendar__tile--active > div {
  width: 100%;
  /*  margin: 0em -0.75em ; */
}
